import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Box,
  Card,
  Typography,
  Divider,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ExportableTable = ({data}) => {
  const [rows, setRows] = useState([]); 

  const dealer_feedback_nps_rating={
    excellent : '9-10',
    fair: '7-8',
    poor: '0-6'
  }

  useEffect(() => {
    if(data){
      const newData = Object.create(data);
      const newRows = newData.map(row=>{
        row.nps_rating = dealer_feedback_nps_rating[row.dealer_feedback];
        return row;
      });
      console.log('newRows',newRows);
      setRows(newRows);
    }
  }, [], data);

  const columns = ['ID', 'Name', 'Inv_no', 'Mobile', 'Date_of_MI', 'NPS_Rating', 'Concern', 'Sub Concern', 'Voice Of Customer', 'Model'];

  const handleExport = () => {
    const columnsToExclude = ["dealer_feedback"];

    const filteredRows = rows.map((row) => {
      const filteredRow = { ...row };
      columnsToExclude.forEach((col) => delete filteredRow[col]);
      return filteredRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');

    // Convert to Excel buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `nps_data.xlsx`);
  };

  return (
    <Card variant="outlined" sx={{boxShadow: 3 , width: '100%', p:'15px'}}>
      <Typography variant="overline" gutterBottom>
        NPS Data
      </Typography>
      <Divider/>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
        marginTop={2}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleExport}
          startIcon={<DownloadIcon />}
        >
          Export to Excel
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            maxHeight: '400px',
            overflow: 'auto', 
            marginBottom:"8px"
        }}
        >
        <Table>
          <TableHead>
          <TableRow>
            {columns.map((column, index) => (
            <TableCell
                key={index}
                sx={{
                backgroundColor: '#635BFF', 
                color: '#ffffff',           
                fontWeight: 'bold',         
                }}
            >
                {column}
            </TableCell>
            ))}
        </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.Customer_id + index}>
                <TableCell>{row.Customer_id}</TableCell>
                <TableCell>{row.Customer_Name}</TableCell>
                <TableCell>{row.Inv_No}</TableCell>
                <TableCell>{row.customerMobileNumber}</TableCell>
                <TableCell>{row.MI_Date}</TableCell>
                <TableCell>{row.nps_rating}</TableCell>
                <TableCell>{row.low_rating_reason}</TableCell>
                <TableCell>{row.low_rating_sub_reason}</TableCell>
                <TableCell>{row.describe_issue}</TableCell>
                <TableCell>{row.Model}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Card>
  );
};

export default ExportableTable;
