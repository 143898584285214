import React, { useEffect, useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import axios from 'axios';

import { useNavigate } from "react-router-dom";
import { useAuth } from '../components/AuthProvider';
import Header from '../components/Header';
import {API_DOMAIN} from '../utils/contants'

const Login = () => {
    const [whatsAppNumber, setWhatsAppNumber] = useState('');
    const [error, setError] = useState('');
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOTP] = useState('');
    const [showLogin, setShowLogin] = useState(false);
    const navigate = useNavigate();
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        // Redirect to dashboard if already authenticated
        if (isAuthenticated) {
          navigate('/dashboard');
        }
      }, [isAuthenticated, navigate]);
  
    const handleWhatsAppNumberChange = (e) => {
        setWhatsAppNumber(e.target.value);
    };

    const handleOTPChange = (e) => {
        setOTP(e.target.value);
    };

    const checkWhatsAppNumber = async () => {
            try{
                await axios.get(`${API_DOMAIN}/otp?whatsAppNumber=${whatsAppNumber}`);
                setShowOTP(true);
                setShowLogin(true);
                setError('');
            }catch(error){
                if (error.status === 404) {
                    setError('WhatsApp Number does not exist');
                }else if (error.status === 500) {
                    setError('Something went wrong, try again later');
                }
            }
    };

    const verifyOTP = async () => {
        try{
            const response = await axios.get(`${API_DOMAIN}/login?whatsAppNumber=${whatsAppNumber}&otp=${otp}`);
            if (response.status === 401) {
                setError('Invalid OTP');
            }else if (response.status === 404) {
                setError('WhatsApp Number does not exist');
            }else if (response.status === 500) {
                setError('Something went wrong, try again later');
            }else{
                window.localStorage.setItem('token', response.data.token);
                window.localStorage.setItem('expiryTime', response.data.expiryTime);
                window.localStorage.setItem('isAuthenticated', 'true');
                login(() => {
                    navigate('/dashboard');
                  });
            }
        }catch(error){
            if (error.response.status === 401) {
                setError('Invalid OTP');
            }else if (error.response.status === 404) {
                setError('WhatsApp Number does not exist');
            }else{
                setError('Something went wrong, try again later');
            }
        }
    }

    return (
        <>
        <Header />
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
    >

            <TextField
                label="WhatsApp Number"
                value={whatsAppNumber}
                onChange={handleWhatsAppNumberChange}
                error={!!error}
                helperText={error}
                variant="outlined"
                margin="normal"
                sx={{ width: '300px' }}
            />
            {showOTP && (
                <TextField
                    label="OTP"
                    value={otp}
                    onChange={handleOTPChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ width: '300px' }}
                />
            )}
            {!showLogin && (<Button variant="contained" color="primary" onClick={checkWhatsAppNumber}>
                Send OTP
            </Button>
            )}
            {showLogin && (<Button variant="contained" color="primary" onClick={verifyOTP}>
                Login
            </Button>
            )}
        </Box>
        </>
    );
};

export default Login;