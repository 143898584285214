import { Card, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer
} from 'recharts';

const FeedbackPieChart = ({data}) => {
  const [feedbackData, setFeedbackData] = useState([]);

  const COLORS = ['#635BFF', '#15B79F','#FB9C0C'];

  function calculateMonthlyNPS(responses) {
    const modelNPS = {
      promoters: 0,
      detractors: 0,
      total: 0,
    };
  
    for (const response of responses) {
      if(response.dealer_feedback){
        if (response.dealer_feedback === "excellent") {
          modelNPS.promoters++;
        } else if (response.dealer_feedback === 'poor') {
          modelNPS.detractors++;
        }else{
          modelNPS.passives++;
        }
        modelNPS.total++;
      }
    }
    const result = [
      { name: 'Promoter', value: modelNPS.promoters },
      { name: 'Passive', value: modelNPS.passives},
      { name: 'Detractor', value: modelNPS.detractors },
    ];
    return result;
  }

  useEffect(() => {
    if(data){
      const result = calculateMonthlyNPS(data);
      setFeedbackData(result);
    }
  }, [], data);

  const CustomLabel = ({ x, y, name, percent, index }) => {
    return (
      <text x={x} y={y} textAnchor="middle" dominantBaseline="middle" fill="#333" fontSize="16">
        {`${name}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Card variant="outlined" sx={{boxShadow: 3 , width: '100%', height: '300px', p:'15px'}}>
      <Typography variant="overline" gutterBottom>
        NPS Category Distribution
      </Typography>
      <Divider/>
      <ResponsiveContainer width="100%" height="90%">
        <PieChart>
          <Pie
            data={feedbackData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={CustomLabel}
            outerRadius={90}
            innerRadius={60}
            dataKey="value"
          >
            {feedbackData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default FeedbackPieChart;
