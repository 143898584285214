import { Card, Divider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts';

const DSENPSBarChart = ({data}) => {
    
    const theme = useTheme();
    const [ratingArr, setRatingArr] = useState(null); 

    function calculateMonthlyNPS(responses) {
      const modelNPS = {};
    
      for (const response of responses) {
        const model = response.DSE;
        if(response.dealer_feedback){
          if (!modelNPS[model]) {
            modelNPS[model] = {
              promoters: 0,
              detractors: 0,
              total: 0,
            };
          }
      
          if (response.dealer_feedback === "excellent") {
            modelNPS[model].promoters++;
          } else if (response.dealer_feedback === 'poor') {
            modelNPS[model].detractors++;
          }
          modelNPS[model].total++;
        }
      }
      let modelWiseNPSArr = [];
      for (const model in modelNPS) {
        const { promoters, detractors, total } = modelNPS[model];
        let  nps = ((promoters - detractors) / total) * 100;
        nps = nps.toFixed(0);
        modelWiseNPSArr.push({model, nps});
      }
      return modelWiseNPSArr;
    }
    

    useEffect(() => {
      if(data){
        setRatingArr(calculateMonthlyNPS(data));
      }
    }, [], data);

    const CustomTick = (props) => {
      const { x, y, payload } = props;
      const words = payload.value.split(' '); 
      const lines = [];
      let line = '';

      words.forEach((word) => {
        if ((line + word).length > 10) {
          lines.push(line);
          line = word;
        } else {
          line = line ? `${line} ${word}` : word;
        }
      });

      lines.push(line);

      return (
        <g transform={`translate(${x},${y + 10})`}>
          {lines.map((line, index) => (
            <text
              key={index}
              x={0}
              y={index * 12}
              textAnchor="middle"
              fill="#666"
              fontSize={12}
            >
              {line}
            </text>
          ))}
        </g>
      );
    };
    
  return (
    <Card variant="outlined" sx={{boxShadow: 3 , width: '100%', height: '300px', p:'15px'}}>
        <Typography variant="overline" gutterBottom>
         DSE wise NPS
        </Typography>
        <Divider/>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={ratingArr}
                margin={{
                    top: 20,
                    right: 30,
                    left: 0,
                    bottom: 50,
                }}
                width={150} 
                height={40}
            >
                <XAxis dataKey="model" tick={<CustomTick />}  ><Label value="DSE Name" offset={-25} position="insideBottom" style={{ fill: '#8884d8' }}/></XAxis>
                <YAxis ><Label
                    value="NPS Score"
                    angle={-90}
                    position="insideLeft"
                    style={{ textAnchor: 'middle',  fill: '#8884d8' }}
                /></YAxis>
                <Tooltip />
                <Bar dataKey="nps" fill='#635BFF'>
                    <LabelList
                        dataKey="nps"
                        position="top"
                        style={{
                            ...theme.typography.caption,
                            fill: theme.palette.text.primary,
                        }}
                />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
        </Card>
  );
};

export default DSENPSBarChart;
