import React from "react";
import { Box, Typography, Button, Container, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Header from '../components/Header';
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const HomePage = () => {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          backgroundColor: "#1565c0",
          color: "white",
          py: 10,
          textAlign: "center",
        }}
      >
        <Container>
          <Typography variant="h2" gutterBottom sx={{ fontWeight: "bold" }}>
            Empower Your Business with Actionable Insights
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 4,
              maxWidth: "700px",
              mx: "auto",
              lineHeight: "1.6",
            }}
          >
            Seamlessly gather customer feedback via WhatsApp, analyze data, and unlock growth opportunities with customized dashboards.
          </Typography>
          <Button variant="contained" color="secondary" size="large" href="#services">
            Explore Our Services
          </Button>
        </Container>
      </Box>

      {/* About Us Section */}
      <Box sx={{ py: 8, backgroundColor: "#f1f1f1" , textAlign: "center",}} id="about">
        <Container >
          <Typography
            variant="h4"
            align="center"
            sx={{
              color: "#1565c0",
              fontWeight: "bold",
              borderBottom: "3px solid #1976d2",
              display: "inline-block",
              mb: 3,
            }}
            id="aboutUs"
          >
            About Us
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{
              mb: 4,
              maxWidth: "800px",
              mx: "auto",
              lineHeight: "1.8",
            }}
          >
            At Bizecho, we specialize in empowering businesses by turning customer feedback into strategic growth opportunities. Our innovative approach ensures every voice is heard, and every insight is actionable. From small startups to established enterprises across industries like restaurants, automobiles, real estate, travel, and hotels, we provide solutions tailored to your unique needs.
          </Typography>
        </Container>
      </Box>

      {/* Services Section */}
      <Box sx={{ py: 8, textAlign: "center", }} id="services">
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{
              color: "#1565c0",
              fontWeight: "bold",
              borderBottom: "3px solid #1976d2",
              display: "inline-block",
              mb: 3,
            }}
            id="services"
          >
            Our Services
          </Typography>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ textAlign: "center", p: 4, backgroundColor: "#e3f2fd", borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: "#1565c0", fontWeight: "bold" }}>
                  WhatsApp Feedback Collection
                </Typography>
                <Typography variant="body1">
                  Engage your customers effortlessly via WhatsApp to collect real-time feedback.
                </Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ textAlign: "center", p: 4, backgroundColor: "#ede7f6", borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: "#6a1b9a", fontWeight: "bold" }}>
                  Data Analysis & Dashboards
                </Typography>
                <Typography variant="body1">
                  Analyze customer feedback with intuitive dashboards designed to provide actionable insights.
                </Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ textAlign: "center", p: 4, backgroundColor: "#f1f8e9", borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: "#2e7d32", fontWeight: "bold" }}>
                  Industry-Specific Solutions
                </Typography>
                <Typography variant="body1">
                  Tailored insights for industries like restaurants, travel, real estate, and more.
                </Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box sx={{ textAlign: "center", p: 4, backgroundColor: "#fff3e0", borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: "#ef6c00", fontWeight: "bold" }}>
                  Seamless Integration
                </Typography>
                <Typography variant="body1">
                  Easily integrate feedback data into your CRM, billing, and other business systems for streamlined workflows.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Contact Us Section */}
      <Box sx={{ py: 8, backgroundColor: "#e0f7fa", textAlign: "center", }} id="contact">
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{
              color: "#00796b",
              fontWeight: "bold",
              borderBottom: "3px solid #004d40",
              display: "inline-block",
              mb: 3,
            }}
            id="contact"
          >
            Contact Us
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{
              mb: 4,
              maxWidth: "600px",
              mx: "auto",
              lineHeight: "1.6",
            }}
          >
            Ready to transform your business with our services? Reach out to us today!
          </Typography>
          <Typography
          variant="body1"
          align="center"
          sx={{
            mb: 4,
            maxWidth: "600px",
            mx: "auto",
            lineHeight: "1.6",
          }}
        >
          We’d love to hear from you! Reach out to us via phone or email, and we’ll be happy to assist.
        </Typography>
        <Stack
          spacing={3}
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="text"
            color="primary"
            startIcon={<PhoneIcon />}
            size="large"
          >
            +91 9315 880 593
          </Button>

          {/* Email Contact */}
          <Button
            variant="text"
            color="secondary"
            startIcon={<EmailIcon />}
            href="mailto:contact@bizecho.co.in"
            size="large"
          >
            contact@bizecho.co.in
          </Button>
        </Stack>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ py: 3, backgroundColor: "#1565c0", color: "white", textAlign: "center" }}>
        <Typography variant="body2">
          © 2024 Bizecho.co.in All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;
