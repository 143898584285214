import { Card, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label,
} from 'recharts';

const SubConcernCategoryBarChart  = ({subConcernData}) => {
    
    const theme = useTheme();

    const CustomTick = (props) => {
      const { x, y, payload } = props;
      const words = payload.value.split(' ');
      const lines = [];
      let line = '';

      words.forEach((word) => {
        if ((line + word).length > 10) {
          lines.push(line);
          line = word;
        } else {
          line = line ? `${line} ${word}` : word;
        }
      });

      lines.push(line);

      return (
        <g transform={`translate(${x},${y + 10})`}>
          {lines.map((line, index) => (
            <text
              key={index}
              x={0}
              y={index * 12}
              textAnchor="middle"
              fill="#666"
              fontSize={12}
            >
              {line}
            </text>
          ))}
        </g>
      );
    };
    
  return (
    <Card variant="outlined" sx={{boxShadow: 3 , width: '100%', height: '300px', p:'15px'}}>
        <Typography variant="overline" gutterBottom>
         Sub Concern Category
        </Typography>
        <Divider/>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={subConcernData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 0,
                    bottom: 50,
                }}
                width={150} 
                height={40}
            >
                <XAxis dataKey="reason" tick={<CustomTick />}  ><Label value="Sub Concern" offset={-25} position="insideBottom" style={{ fill: '#8884d8' }}/></XAxis>
                <YAxis ><Label
                    value="Count"
                    angle={-90}
                    position="insideLeft"
                    style={{ textAnchor: 'middle',  fill: '#8884d8' }}
                /></YAxis>
                <Tooltip />
                <Bar dataKey="value" fill='#635BFF'>
                    <LabelList
                        dataKey="value"
                        position="top"
                        style={{
                            ...theme.typography.caption,
                            fill: theme.palette.text.primary,
                        }}
                />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
        </Card>
  );
};

export default SubConcernCategoryBarChart ;
