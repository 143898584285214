import { useState, useEffect } from 'react';

// Function to check if the user is authenticated
export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check localStorage for the authentication status on app load
  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  // Login function that sets authentication in localStorage
  const login = (callback) => {
    localStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
    if (callback) callback();
  };

  // Logout function that removes authentication from localStorage
  const logout = (callback) => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('expiryTime');
    setIsAuthenticated(false);
    if (callback) callback();
  };

  return {
    isAuthenticated,
    login,
    logout,
  };
};
