import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useAuth } from '../components/AuthProvider';
import { Box, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <AppBar 
      position="static" 
      sx={{ backgroundColor: '#01458E' }}
    >
      <Toolbar sx={{ padding: 0 }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Link underline="none" href="/" color="inherit" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="span" sx={{ fontWeight: 'bold', letterSpacing: 2 }}>
              B
            </Box>
            <Box component="span" sx={{ fontWeight: 'bold', color: 'red',letterSpacing: 2  }}>
              !
            </Box>
            <Box component="span" sx={{ fontWeight: 'bold', letterSpacing: 2 }}>
              ZECHO
            </Box>
          </Link>
          {isAuthenticated && (
          <>
          <Button 
            variant="outlined"
            onClick={()=>navigate('/dashboard')}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            Dashboard
          </Button>
          <Button 
            variant="outlined"
            onClick={()=>navigate('/add-customer')}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            Add Customer
          </Button>
          </>
        )}
        </Typography>
        
        {isAuthenticated && (
          
          <Button 
            variant="outlined"
            onClick={()=>logout(navigate('/'))}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            Logout
          </Button>
        )}

        {!isAuthenticated && window.location.pathname !== '/login' && (
          <>
          <Button 
            variant="outlined"
            onClick={() => scrollToSection("aboutUs")}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            About Us
          </Button>   
          <Button 
            variant="outlined"
            onClick={() => scrollToSection("services")}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            Services
          </Button> 
          <Button 
            variant="outlined"
            onClick={() => scrollToSection("contact")}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            Contact Us
          </Button> 
          
          <Button 
            variant="outlined"
            onClick={()=>navigate('/login')}
            style={{ 
              color: 'white', 
              backgroundColor: 'transparent', 
              border: 'none', 
              cursor: 'pointer' 
            }}
          >
            Login
          </Button>   
          </>
        )}

      </Toolbar>
    </AppBar>
  );
}

export default Header;
