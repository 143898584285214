import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    // Check localStorage for authentication
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

    // If not authenticated, redirect to login page
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // If authenticated, render the children (protected component)
    return children;
};

export default ProtectedRoute;
