import { Card, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const NPSScroreWidget = ({score}) => {
  return (
    <Card variant="outlined" sx={{boxShadow: 3 , width: '100%', height: '300px', p:'15px'}}>
      <Typography variant="overline" gutterBottom>
        NPS Score
      </Typography>
      <Divider/>
      <Typography variant="h4" gutterBottom sx={{marginTop:'30px'}}>
      {score}
      </Typography>
      {/* <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={50}
            outerRadius={100}
            startAngle={180}
            endAngle={0}
            fill="#635BFF"
            paddingAngle={0}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
              value={npsScore}
              position="center"
              style={{
                fontSize: '24px',
                fontWeight: 'bold'
              }}
            />
          </Pie>
          <Tooltip />
        </PieChart>
        
      </ResponsiveContainer> */}
      <LinearProgress variant="buffer" value={score} valueBuffer={100} sx={{height:'30px', marginTop:'30px', backgroundColor: '#635BFF'}}/>
      </Card>
  );
};

export default NPSScroreWidget;
